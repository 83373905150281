import React, { SVGProps } from 'react';

export const CircleProgress1 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.48373 6.4847C5.55144 7.41699 4.90636 8.52316 4.54491 9.69736C4.32556 10.41 3.57008 10.8098 2.85749 10.5905C2.1449 10.3711 1.74505 9.61562 1.9644 8.90303C2.45224 7.31822 3.32347 5.82658 4.57454 4.57552C5.82561 3.32445 7.31724 2.45321 8.90205 1.96538C9.61464 1.74603 10.3701 2.14588 10.5895 2.85846C10.8088 3.57105 10.409 4.32654 9.69639 4.54589C8.52218 4.90733 7.41602 5.55242 6.48373 6.4847ZM2.85749 13.4098C3.57008 13.1905 4.32556 13.5903 4.54491 14.3029C4.90636 15.4771 5.55144 16.5833 6.48373 17.5156C7.41602 18.4479 8.52218 19.0929 9.69639 19.4544C10.409 19.6737 10.8088 20.4292 10.5895 21.1418C10.3701 21.8544 9.61464 22.2542 8.90205 22.0349C7.31724 21.5471 5.82561 20.6758 4.57454 19.4248C3.32347 18.1737 2.45224 16.6821 1.9644 15.0972C1.74505 14.3847 2.1449 13.6292 2.85749 13.4098ZM13.4088 2.85846C13.6282 2.14588 14.3837 1.74603 15.0963 1.96538C16.6811 2.45321 18.1727 3.32445 19.4238 4.57552C20.6749 5.82659 21.5461 7.31822 22.0339 8.90303C22.2533 9.61562 21.8534 10.3711 21.1408 10.5905C20.4282 10.8098 19.6728 10.41 19.4534 9.69736C19.092 8.52316 18.4469 7.41699 17.5146 6.48471C16.5823 5.55242 15.4761 4.90733 14.3019 4.54589C13.5893 4.32654 13.1895 3.57105 13.4088 2.85846ZM13.4088 21.1418C13.1895 20.4292 13.5893 19.6737 14.3019 19.4544C15.4761 19.0929 16.5823 18.4479 17.5146 17.5156C18.4469 16.5833 19.092 15.4771 19.4534 14.3029C19.6728 13.5903 20.4282 13.1905 21.1408 13.4098C21.8534 13.6292 22.2533 14.3847 22.0339 15.0972C21.5461 16.6821 20.6749 18.1737 19.4238 19.4248C18.1727 20.6758 16.6811 21.5471 15.0963 22.0349C14.3837 22.2542 13.6282 21.8544 13.4088 21.1418Z"
        fill="#E7E7EF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.85749 13.4098C3.57008 13.1905 4.32556 13.5903 4.54491 14.3029C4.90636 15.4771 5.55144 16.5833 6.48373 17.5156C7.41602 18.4479 8.52218 19.093 9.69639 19.4544C10.409 19.6737 10.8088 20.4292 10.5895 21.1418C10.3701 21.8544 9.61464 22.2543 8.90205 22.0349C7.31724 21.5471 5.82561 20.6758 4.57454 19.4248C3.32347 18.1737 2.45224 16.6821 1.9644 15.0973C1.74505 14.3847 2.1449 13.6292 2.85749 13.4098Z"
        fill="#D61F7B"
      />
    </svg>
  );
};

export const CircleProgress2 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.48373 6.4847C5.55144 7.41699 4.90636 8.52316 4.54491 9.69736C4.32556 10.41 3.57008 10.8098 2.85749 10.5905C2.1449 10.3711 1.74505 9.61562 1.9644 8.90303C2.45224 7.31822 3.32347 5.82658 4.57454 4.57552C5.82561 3.32445 7.31724 2.45321 8.90205 1.96538C9.61464 1.74603 10.3701 2.14588 10.5895 2.85846C10.8088 3.57105 10.409 4.32654 9.69639 4.54589C8.52218 4.90733 7.41602 5.55242 6.48373 6.4847ZM2.85749 13.4098C3.57008 13.1905 4.32556 13.5903 4.54491 14.3029C4.90636 15.4771 5.55144 16.5833 6.48373 17.5156C7.41602 18.4479 8.52218 19.0929 9.69639 19.4544C10.409 19.6737 10.8088 20.4292 10.5895 21.1418C10.3701 21.8544 9.61464 22.2542 8.90205 22.0349C7.31724 21.5471 5.82561 20.6758 4.57454 19.4248C3.32347 18.1737 2.45224 16.6821 1.9644 15.0972C1.74505 14.3847 2.1449 13.6292 2.85749 13.4098ZM13.4088 2.85846C13.6282 2.14588 14.3837 1.74603 15.0963 1.96538C16.6811 2.45321 18.1727 3.32445 19.4238 4.57552C20.6749 5.82659 21.5461 7.31822 22.0339 8.90303C22.2533 9.61562 21.8534 10.3711 21.1408 10.5905C20.4282 10.8098 19.6728 10.41 19.4534 9.69736C19.092 8.52316 18.4469 7.41699 17.5146 6.48471C16.5823 5.55242 15.4761 4.90733 14.3019 4.54589C13.5893 4.32654 13.1895 3.57105 13.4088 2.85846ZM13.4088 21.1418C13.1895 20.4292 13.5893 19.6737 14.3019 19.4544C15.4761 19.0929 16.5823 18.4479 17.5146 17.5156C18.4469 16.5833 19.092 15.4771 19.4534 14.3029C19.6728 13.5903 20.4282 13.1905 21.1408 13.4098C21.8534 13.6292 22.2533 14.3847 22.0339 15.0972C21.5461 16.6821 20.6749 18.1737 19.4238 19.4248C18.1727 20.6758 16.6811 21.5471 15.0963 22.0349C14.3837 22.2542 13.6282 21.8544 13.4088 21.1418Z"
        fill="#E7E7EF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.48373 6.4847C5.55144 7.41699 4.90636 8.52316 4.54491 9.69736C4.32556 10.41 3.57008 10.8098 2.85749 10.5905C2.1449 10.3711 1.74505 9.61562 1.9644 8.90303C2.45224 7.31822 3.32347 5.82658 4.57454 4.57552C5.82561 3.32445 7.31724 2.45321 8.90205 1.96538C9.61464 1.74603 10.3701 2.14588 10.5895 2.85846C10.8088 3.57105 10.409 4.32654 9.69639 4.54589C8.52218 4.90733 7.41602 5.55242 6.48373 6.4847ZM2.85749 13.4098C3.57008 13.1905 4.32556 13.5903 4.54491 14.3029C4.90636 15.4771 5.55144 16.5833 6.48373 17.5156C7.41602 18.4479 8.52218 19.0929 9.69639 19.4544C10.409 19.6737 10.8088 20.4292 10.5895 21.1418C10.3701 21.8544 9.61464 22.2542 8.90205 22.0349C7.31724 21.5471 5.82561 20.6758 4.57454 19.4248C3.32347 18.1737 2.45224 16.6821 1.9644 15.0972C1.74505 14.3847 2.1449 13.6292 2.85749 13.4098Z"
        fill="#D61F7B"
      />
    </svg>
  );
};

export const CircleProgress3 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.48373 6.4847C5.55144 7.41699 4.90636 8.52316 4.54491 9.69736C4.32556 10.41 3.57008 10.8098 2.85749 10.5905C2.1449 10.3711 1.74505 9.61562 1.9644 8.90303C2.45224 7.31822 3.32347 5.82658 4.57454 4.57552C5.82561 3.32445 7.31724 2.45321 8.90205 1.96538C9.61464 1.74603 10.3701 2.14588 10.5895 2.85846C10.8088 3.57105 10.409 4.32654 9.69639 4.54589C8.52218 4.90733 7.41602 5.55242 6.48373 6.4847ZM2.85749 13.4098C3.57008 13.1905 4.32556 13.5903 4.54491 14.3029C4.90636 15.4771 5.55144 16.5833 6.48373 17.5156C7.41602 18.4479 8.52218 19.0929 9.69639 19.4544C10.409 19.6737 10.8088 20.4292 10.5895 21.1418C10.3701 21.8544 9.61464 22.2542 8.90205 22.0349C7.31724 21.5471 5.82561 20.6758 4.57454 19.4248C3.32347 18.1737 2.45224 16.6821 1.9644 15.0972C1.74505 14.3847 2.1449 13.6292 2.85749 13.4098ZM13.4088 2.85846C13.6282 2.14588 14.3837 1.74603 15.0963 1.96538C16.6811 2.45321 18.1727 3.32445 19.4238 4.57552C20.6749 5.82659 21.5461 7.31822 22.0339 8.90303C22.2533 9.61562 21.8534 10.3711 21.1408 10.5905C20.4282 10.8098 19.6728 10.41 19.4534 9.69736C19.092 8.52316 18.4469 7.41699 17.5146 6.48471C16.5823 5.55242 15.4761 4.90733 14.3019 4.54589C13.5893 4.32654 13.1895 3.57105 13.4088 2.85846ZM13.4088 21.1418C13.1895 20.4292 13.5893 19.6737 14.3019 19.4544C15.4761 19.0929 16.5823 18.4479 17.5146 17.5156C18.4469 16.5833 19.092 15.4771 19.4534 14.3029C19.6728 13.5903 20.4282 13.1905 21.1408 13.4098C21.8534 13.6292 22.2533 14.3847 22.0339 15.0972C21.5461 16.6821 20.6749 18.1737 19.4238 19.4248C18.1727 20.6758 16.6811 21.5471 15.0963 22.0349C14.3837 22.2542 13.6282 21.8544 13.4088 21.1418Z"
        fill="#E7E7EF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.48373 6.4847C5.55144 7.41699 4.90636 8.52316 4.54491 9.69736C4.32556 10.41 3.57008 10.8098 2.85749 10.5905C2.1449 10.3711 1.74505 9.61562 1.9644 8.90303C2.45224 7.31822 3.32347 5.82658 4.57454 4.57552C5.82561 3.32445 7.31724 2.45321 8.90205 1.96538C9.61464 1.74603 10.3701 2.14588 10.5895 2.85846C10.8088 3.57105 10.409 4.32654 9.69639 4.54589C8.52218 4.90733 7.41602 5.55242 6.48373 6.4847ZM2.85749 13.4098C3.57008 13.1905 4.32556 13.5903 4.54491 14.3029C4.90636 15.4771 5.55144 16.5833 6.48373 17.5156C7.41602 18.4479 8.52218 19.0929 9.69639 19.4544C10.409 19.6737 10.8088 20.4292 10.5895 21.1418C10.3701 21.8544 9.61464 22.2542 8.90205 22.0349C7.31724 21.5471 5.82561 20.6758 4.57454 19.4248C3.32347 18.1737 2.45224 16.6821 1.9644 15.0972C1.74505 14.3847 2.1449 13.6292 2.85749 13.4098ZM13.4088 2.85846C13.6282 2.14588 14.3837 1.74603 15.0963 1.96538C16.6811 2.45321 18.1727 3.32445 19.4238 4.57552C20.6749 5.82659 21.5461 7.31822 22.0339 8.90303C22.2533 9.61562 21.8534 10.3711 21.1408 10.5905C20.4282 10.8098 19.6728 10.41 19.4534 9.69736C19.092 8.52316 18.4469 7.41699 17.5146 6.48471C16.5823 5.55242 15.4761 4.90733 14.3019 4.54589C13.5893 4.32654 13.1895 3.57105 13.4088 2.85846Z"
        fill="#D61F7B"
      />
    </svg>
  );
};

export const CircleProgress4 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.48373 6.4847C5.55144 7.41699 4.90636 8.52316 4.54491 9.69736C4.32556 10.41 3.57008 10.8098 2.85749 10.5905C2.1449 10.3711 1.74505 9.61562 1.9644 8.90303C2.45224 7.31822 3.32347 5.82658 4.57454 4.57552C5.82561 3.32445 7.31724 2.45321 8.90205 1.96538C9.61464 1.74603 10.3701 2.14588 10.5895 2.85846C10.8088 3.57105 10.409 4.32654 9.69639 4.54589C8.52218 4.90733 7.41602 5.55242 6.48373 6.4847ZM2.85749 13.4098C3.57008 13.1905 4.32556 13.5903 4.54491 14.3029C4.90636 15.4771 5.55144 16.5833 6.48373 17.5156C7.41602 18.4479 8.52218 19.0929 9.69639 19.4544C10.409 19.6737 10.8088 20.4292 10.5895 21.1418C10.3701 21.8544 9.61464 22.2542 8.90205 22.0349C7.31724 21.5471 5.82561 20.6758 4.57454 19.4248C3.32347 18.1737 2.45224 16.6821 1.9644 15.0972C1.74505 14.3847 2.1449 13.6292 2.85749 13.4098ZM13.4088 2.85846C13.6282 2.14588 14.3837 1.74603 15.0963 1.96538C16.6811 2.45321 18.1727 3.32445 19.4238 4.57552C20.6749 5.82659 21.5461 7.31822 22.0339 8.90303C22.2533 9.61562 21.8534 10.3711 21.1408 10.5905C20.4282 10.8098 19.6728 10.41 19.4534 9.69736C19.092 8.52316 18.4469 7.41699 17.5146 6.48471C16.5823 5.55242 15.4761 4.90733 14.3019 4.54589C13.5893 4.32654 13.1895 3.57105 13.4088 2.85846ZM13.4088 21.1418C13.1895 20.4292 13.5893 19.6737 14.3019 19.4544C15.4761 19.0929 16.5823 18.4479 17.5146 17.5156C18.4469 16.5833 19.092 15.4771 19.4534 14.3029C19.6728 13.5903 20.4282 13.1905 21.1408 13.4098C21.8534 13.6292 22.2533 14.3847 22.0339 15.0972C21.5461 16.6821 20.6749 18.1737 19.4238 19.4248C18.1727 20.6758 16.6811 21.5471 15.0963 22.0349C14.3837 22.2542 13.6282 21.8544 13.4088 21.1418Z"
        fill="#E7E7EF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.48373 6.4847C5.55144 7.41699 4.90636 8.52316 4.54491 9.69736C4.32556 10.41 3.57008 10.8098 2.85749 10.5905C2.1449 10.3711 1.74505 9.61562 1.9644 8.90303C2.45224 7.31822 3.32347 5.82658 4.57454 4.57552C5.82561 3.32445 7.31724 2.45321 8.90205 1.96538C9.61464 1.74603 10.3701 2.14588 10.5895 2.85846C10.8088 3.57105 10.409 4.32654 9.69639 4.54589C8.52218 4.90733 7.41602 5.55242 6.48373 6.4847ZM2.85749 13.4098C3.57008 13.1905 4.32556 13.5903 4.54491 14.3029C4.90636 15.4771 5.55144 16.5833 6.48373 17.5156C7.41602 18.4479 8.52218 19.0929 9.69639 19.4544C10.409 19.6737 10.8088 20.4292 10.5895 21.1418C10.3701 21.8544 9.61464 22.2542 8.90205 22.0349C7.31724 21.5471 5.82561 20.6758 4.57454 19.4248C3.32347 18.1737 2.45224 16.6821 1.9644 15.0972C1.74505 14.3847 2.1449 13.6292 2.85749 13.4098ZM13.4088 2.85846C13.6282 2.14588 14.3837 1.74603 15.0963 1.96538C16.6811 2.45321 18.1727 3.32445 19.4238 4.57552C20.6749 5.82659 21.5461 7.31822 22.0339 8.90303C22.2533 9.61562 21.8534 10.3711 21.1408 10.5905C20.4282 10.8098 19.6728 10.41 19.4534 9.69736C19.092 8.52316 18.4469 7.41699 17.5146 6.48471C16.5823 5.55242 15.4761 4.90733 14.3019 4.54589C13.5893 4.32654 13.1895 3.57105 13.4088 2.85846ZM13.4088 21.1418C13.1895 20.4292 13.5893 19.6737 14.3019 19.4544C15.4761 19.0929 16.5823 18.4479 17.5146 17.5156C18.4469 16.5833 19.092 15.4771 19.4534 14.3029C19.6728 13.5903 20.4282 13.1905 21.1408 13.4098C21.8534 13.6292 22.2533 14.3847 22.0339 15.0972C21.5461 16.6821 20.6749 18.1737 19.4238 19.4248C18.1727 20.6758 16.6811 21.5471 15.0963 22.0349C14.3837 22.2542 13.6282 21.8544 13.4088 21.1418Z"
        fill="#D61F7B"
      />
    </svg>
  );
};
